import { useState, useEffect } from "preact/hooks";

import { Game } from "./game.js";
import { MainMenu } from "./main-menu.js";

import { eventTrigger } from "../../events";

export const GameController = () => {
  const [started, setStarted] = useState(false);

  const handleStartGame = () => {
    if (started) return;
    eventTrigger("start");
    setStarted(true);
  };

  const handleExitGame = () => {
    if (!started) return;
    eventTrigger("exit");
    setStarted(false);
  };

  return started ? (
    <Game handleExitGame={handleExitGame} />
  ) : (
    <MainMenu handleStartGame={handleStartGame} />
  );
};
