import { useEffect } from "preact/hooks";

const AdComponent = () => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-7721860996383757"
      data-ad-slot="3951407660"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export { AdComponent };
