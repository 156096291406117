// const API_ORIGIN = "http://localhost:3000";
// const API_ORIGIN = "https://d142jn6ryh.execute-api.us-east-1.amazonaws.com";

import { buildQuestion, checkAnswer } from "./data/questions";

const headers = {
  "Content-Type": "application/json",
};

// export const startGame = async () => {
//   const res = await fetch(`${API_ORIGIN}/game/start`, {
//     method: "POST",
//     headers,
//   });

//   if (!res.ok) throw res;

//   const json = await res.json();

//   return json.id;
// };

export const getQuesiton = async () => {
  const question = buildQuestion();
  return question;
};

export const submitAnswer = async (question, answer) => {
  const stationName = question.name;
  const answerOptions = question.answers;
  const correctAnswer = answerOptions.find((ansOption) =>
    checkAnswer(stationName, ansOption)
  );

  const correct = correctAnswer === answer;

  if (correct) {
    return { status: true };
  } else {
    return { status: false, correct: correctAnswer };
  }
};
