import "./style";

import { GameController } from "./pages/game";

import Layout from "./pages/layout";

export default function App() {
  return (
    <Layout>
      <GameController />
    </Layout>
  );
}
