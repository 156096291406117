import React from "react";

import styles from "./style.module.css";

import { AdComponent } from "../../components/AdComp";

const Header = () => {
  return (
    <header className={styles.header}>
      <h1 className={styles.title}>
        <span className={styles.small}>THE</span> TUBE LINE{" "}
        <span className={styles.small}>GAME</span>
      </h1>
    </header>
  );
};

const Layout = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.contentWrapper}>
        <div className={styles.content}>{children}</div>
        {/* <AdComponent /> */}
      </div>
    </div>
  );
};

export default Layout;
