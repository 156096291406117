import styles from "./style.module.css";

export const MainMenu = ({ handleStartGame }) => {
  return (
    <div>
      <h2>Put your tube knowledge to the test!</h2>

      <button onClick={handleStartGame} className={styles.blueButton}>
        Start Game!
      </button>
    </div>
  );
};
