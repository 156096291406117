// string[]
export const allLines = [
  "Bakerloo",
  "Central",
  "Circle",
  "District",
  "Hammersmith & City",
  "Jubilee",
  "Metropolitan",
  "Northern",
  "Piccadilly",
  "Victoria",
  "Waterloo & City",
];

// string[]
export const stationNames = [
  "Acton Town",
  "Aldgate East",
  "Alperton",
  "Amersham",
  "Angel",
  "Archway",
  "Arnos Grove",
  "Arsenal",
  "Baker Street",
  "Balham",
  "Bank",
  "Barbican",
  "Barking",
  "Barkingside",
  "Barons Court",
  "Bayswater",
  "Becontree",
  "Belsize Park",
  "Bermondsey",
  "Bethnal Green",
  "Blackfriars",
  "Blackhorse Road",
  "Bond Street",
  "Borough",
  "Boston Manor",
  "Bounds Green",
  "Bow Road",
  "Brent Cross",
  "Brixton",
  "Bromley-by-Bow",
  "Buckhurst Hill",
  "Burnt Oak",
  "Caledonian Road",
  "Camden Town",
  "Canada Water",
  "Canary Wharf",
  "Canning Town",
  "Cannon Street",
  "Canons Park",
  "Chalfont & Latimer",
  "Chalk Farm",
  "Chancery Lane",
  "Charing Cross",
  "Chesham",
  "Chigwell",
  "Chiswick Park",
  "Chorleywood",
  "Clapham Common",
  "Clapham North",
  "Clapham South",
  "Cockfosters",
  "Colindale",
  "Colliers Wood",
  "Covent Garden",
  "Croxley",
  "Dagenham East",
  "Dagenham Heathway",
  "Debden",
  "Dollis Hill",
  "Ealing Broadway",
  "Ealing Common",
  "Earl's Court",
  "East Acton",
  "East Finchley",
  "East Ham",
  "East Putney",
  "Eastcote",
  "Edgware",
  "Edgware Road",
  "Edgware Road",
  "Elephant & Castle",
  "Elm Park",
  "Embankment",
  "Epping",
  "Euston",
  "Euston Square",
  "Fairlop",
  "Farringdon",
  "Finchley Central",
  "Finchley Road",
  "Finsbury Park",
  "Fulham Broadway",
  "Gants Hill",
  "Gloucester Road",
  "Golders Green",
  "Goldhawk Road",
  "Goodge Street",
  "Grange Hill",
  "Great Portland Street",
  "Green Park",
  "Greenford",
  "Gunnersbury",
  "Hainault",
  "Hammersmith",
  "Hammersmith",
  "Hampstead",
  "Hanger Lane",
  "Harlesden",
  "Harrow & Wealdstone",
  "Harrow-on-the-Hill",
  "Hatton Cross",
  "Heathrow Terminal 4",
  "Heathrow Terminal 5",
  "Heathrow Terminals 1, 2, 3",
  "Hendon Central",
  "High Barnet",
  "High Street Kensington",
  "Highbury & Islington",
  "Highgate",
  "Hillingdon",
  "Holborn",
  "Holland Park",
  "Holloway Road",
  "Hornchurch",
  "Hounslow Central",
  "Hounslow East",
  "Hounslow West",
  "Hyde Park Corner",
  "Ickenham",
  "Kennington",
  "Kensal Green",
  "Kensington (Olympia)",
  "Kentish Town",
  "Kenton",
  "Kew Gardens",
  "Kilburn",
  "Kilburn Park",
  "King's Cross St. Pancras",
  "Kingsbury",
  "Knightsbridge",
  "Ladbroke Grove",
  "Lambeth North",
  "Lancaster Gate",
  "Latimer Road",
  "Leicester Square",
  "Leyton",
  "Leytonstone",
  "Liverpool Street",
  "London Bridge",
  "Loughton",
  "Maida Vale",
  "Manor House",
  "Mansion House",
  "Marble Arch",
  "Marylebone",
  "Mile End",
  "Mill Hill East",
  "Monument",
  "Moor Park",
  "Moorgate",
  "Morden",
  "Mornington Crescent",
  "Neasden",
  "Newbury Park",
  "North Acton",
  "North Ealing",
  "North Greenwich",
  "North Harrow",
  "North Wembley",
  "Northfields",
  "Northolt",
  "Northwick Park",
  "Northwood",
  "Northwood Hills",
  "Notting Hill Gate",
  "Oakwood",
  "Old Street",
  "Osterley",
  "Oval",
  "Oxford Circus",
  "Paddington",
  "Park Royal",
  "Parsons Green",
  "Perivale",
  "Piccadilly Circus",
  "Pimlico",
  "Pinner",
  "Plaistow",
  "Preston Road",
  "Putney Bridge",
  "Queen's Park",
  "Queensbury",
  "Queensway",
  "Ravenscourt Park",
  "Rayners Lane",
  "Redbridge",
  "Regent's Park",
  "Richmond",
  "Rickmansworth",
  "Roding Valley",
  "Royal Oak",
  "Ruislip",
  "Ruislip Gardens",
  "Ruislip Manor",
  "Russell Square",
  "Seven Sisters",
  "Shepherd's Bush",
  "Shepherd's Bush Market",
  "Sloane Square",
  "Snaresbrook",
  "South Ealing",
  "South Harrow",
  "South Kensington",
  "South Kenton",
  "South Ruislip",
  "South Wimbledon",
  "South Woodford",
  "Southfields",
  "Southgate",
  "Southwark",
  "St. James's Park",
  "St. John's Wood",
  "St. Paul's",
  "Stamford Brook",
  "Stanmore",
  "Stepney Green",
  "Stockwell",
  "Stonebridge Park",
  "Stratford",
  "Sudbury Hill",
  "Sudbury Town",
  "Swiss Cottage",
  "Temple",
  "Theydon Bois",
  "Tooting Bec",
  "Tooting Broadway",
  "Tottenham Court Road",
  "Tottenham Hale",
  "Totteridge & Whetstone",
  "Tower Hill",
  "Tufnell Park",
  "Turnham Green",
  "Turnpike Lane",
  "Upminster",
  "Upminster Bridge",
  "Upney",
  "Upton Park",
  "Uxbridge",
  "Vauxhall",
  "Victoria",
  "Walthamstow Central",
  "Wanstead",
  "Warren Street",
  "Warwick Avenue",
  "Waterloo",
  "Watford",
  "Wembley Central",
  "Wembley Park",
  "West Acton",
  "West Brompton",
  "West Finchley",
  "West Ham",
  "West Hampstead",
  "West Harrow",
  "West Kensington",
  "West Ruislip",
  "Westbourne Park",
  "Westminster",
  "White City",
  "Whitechapel",
  "Willesden Green",
  "Willesden Junction",
  "Wimbledon",
  "Wimbledon Park",
  "Wood Green",
  "Wood Lane",
  "Woodford",
  "Woodside Park",
];

// type StationObj = {
//   Station: string,
//   Zone: string,
//   Opened: string,
//   Lines: string[],
// };
// Record<string, StationObj>
export const stationsObj = {
  "Acton Town": {
    Station: "Acton Town",
    Zone: "3",
    Opened: "1 July 1879",
    Lines: ["District", "Piccadilly", "Circle"],
  },
  "Aldgate East": {
    Station: "Aldgate East",
    Zone: "1",
    Opened: "6 October 1884",
    Lines: ["Hammersmith & City", "District"],
  },
  Alperton: {
    Station: "Alperton",
    Zone: "4",
    Opened: "28-Jun-03",
    Lines: ["Piccadilly"],
  },
  Amersham: {
    Station: "Amersham",
    Zone: "9",
    Opened: "1 September 1892",
    Lines: ["Metropolitan"],
  },
  Angel: {
    Station: "Angel",
    Zone: "1",
    Opened: "17-Nov-01",
    Lines: ["Northern"],
  },
  Archway: {
    Station: "Archway",
    Zone: "2 & 3",
    Opened: "22-Jun-07",
    Lines: ["Northern"],
  },
  "Arnos Grove": {
    Station: "Arnos Grove",
    Zone: "4",
    Opened: "19-Sep-32",
    Lines: ["Piccadilly"],
  },
  Arsenal: {
    Station: "Arsenal",
    Zone: "2",
    Opened: "15-Dec-06",
    Lines: ["Piccadilly"],
  },
  "Baker Street": {
    Station: "Baker Street",
    Zone: "1",
    Opened: "10 January 1863",
    Lines: [
      "Metropolitan",
      "Bakerloo",
      "Circle",
      "Jubilee",
      "Hammersmith & City",
    ],
  },
  Balham: {
    Station: "Balham",
    Zone: "3",
    Opened: "6-Dec-26",
    Lines: ["Northern"],
  },
  Bank: {
    Station: "Bank",
    Zone: "1",
    Opened: "8 August 1898",
    Lines: ["Waterloo & City", "Northern", "Central"],
  },
  Barbican: {
    Station: "Barbican",
    Zone: "1",
    Opened: "23 December 1865",
    Lines: ["Metropolitan", "Circle", "Hammersmith & City"],
  },
  Barking: {
    Station: "Barking",
    Zone: "4",
    Opened: "2-Jun-02",
    Lines: ["District", "Hammersmith & City"],
  },
  Barkingside: {
    Station: "Barkingside",
    Zone: "4",
    Opened: "31-May-48",
    Lines: ["Central"],
  },
  "Barons Court": {
    Station: "Barons Court",
    Zone: "2",
    Opened: "9-Oct-05",
    Lines: ["District", "Piccadilly"],
  },
  Bayswater: {
    Station: "Bayswater",
    Zone: "1",
    Opened: "1 October 1868",
    Lines: ["District", "Circle"],
  },
  Becontree: {
    Station: "Becontree",
    Zone: "5",
    Opened: "18-Jul-32",
    Lines: ["District"],
  },
  "Belsize Park": {
    Station: "Belsize Park",
    Zone: "2",
    Opened: "22-Jun-07",
    Lines: ["Northern"],
  },
  Bermondsey: {
    Station: "Bermondsey",
    Zone: "2",
    Opened: "17-Sep-99",
    Lines: ["Jubilee"],
  },
  "Bethnal Green": {
    Station: "Bethnal Green",
    Zone: "2",
    Opened: "4-Dec-46",
    Lines: ["Central"],
  },
  Blackfriars: {
    Station: "Blackfriars",
    Zone: "1",
    Opened: "30 May 1870",
    Lines: ["District", "Circle"],
  },
  "Blackhorse Road": {
    Station: "Blackhorse Road",
    Zone: "3",
    Opened: "1-Sep-68",
    Lines: ["Victoria"],
  },
  "Bond Street": {
    Station: "Bond Street",
    Zone: "1",
    Opened: "24-Sep-00",
    Lines: ["Central", "Jubilee"],
  },
  Borough: {
    Station: "Borough",
    Zone: "1",
    Opened: "18 December 1890",
    Lines: ["Northern"],
  },
  "Boston Manor": {
    Station: "Boston Manor",
    Zone: "4",
    Opened: "1 May 1883",
    Lines: ["Piccadilly"],
  },
  "Bounds Green": {
    Station: "Bounds Green",
    Zone: "3 & 4",
    Opened: "19-Sep-32",
    Lines: ["Piccadilly"],
  },
  "Bow Road": {
    Station: "Bow Road",
    Zone: "2",
    Opened: "11-Jun-02",
    Lines: ["District", "Hammersmith & City"],
  },
  "Brent Cross": {
    Station: "Brent Cross",
    Zone: "3",
    Opened: "19-Nov-23",
    Lines: ["Northern"],
  },
  Brixton: {
    Station: "Brixton",
    Zone: "2",
    Opened: "23-Jul-71",
    Lines: ["Victoria"],
  },
  "Bromley-by-Bow": {
    Station: "Bromley-by-Bow",
    Zone: "2 & 3",
    Opened: "2-Jun-02",
    Lines: ["District", "Hammersmith & City"],
  },
  "Buckhurst Hill": {
    Station: "Buckhurst Hill",
    Zone: "5",
    Opened: "21-Nov-48",
    Lines: ["Central"],
  },
  "Burnt Oak": {
    Station: "Burnt Oak",
    Zone: "4",
    Opened: "27-Oct-24",
    Lines: ["Northern"],
  },
  "Caledonian Road": {
    Station: "Caledonian Road",
    Zone: "2",
    Opened: "15-Dec-06",
    Lines: ["Piccadilly"],
  },
  "Camden Town": {
    Station: "Camden Town",
    Zone: "2",
    Opened: "22-Jun-07",
    Lines: ["Northern"],
  },
  "Canada Water": {
    Station: "Canada Water",
    Zone: "2",
    Opened: "17-Sep-99",
    Lines: ["Jubilee"],
  },
  "Canary Wharf": {
    Station: "Canary Wharf",
    Zone: "2",
    Opened: "17-Sep-99",
    Lines: ["Jubilee"],
  },
  "Canning Town": {
    Station: "Canning Town",
    Zone: "3",
    Opened: "14-May-99",
    Lines: ["Jubilee"],
  },
  "Cannon Street": {
    Station: "Cannon Street",
    Zone: "1",
    Opened: "6 October 1884",
    Lines: ["District", "Circle"],
  },
  "Canons Park": {
    Station: "Canons Park",
    Zone: "5",
    Opened: "10-Dec-32",
    Lines: ["Jubilee"],
  },
  "Chalfont & Latimer": {
    Station: "Chalfont & Latimer",
    Zone: "8",
    Opened: "8 July 1889",
    Lines: ["Metropolitan"],
  },
  "Chalk Farm": {
    Station: "Chalk Farm",
    Zone: "2",
    Opened: "22-Jun-07",
    Lines: ["Northern"],
  },
  "Chancery Lane": {
    Station: "Chancery Lane",
    Zone: "1",
    Opened: "30-Jul-00",
    Lines: ["Central"],
  },
  "Charing Cross": {
    Station: "Charing Cross",
    Zone: "1",
    Opened: "10-Mar-06",
    Lines: ["Bakerloo", "Northern"],
  },
  Chesham: {
    Station: "Chesham",
    Zone: "9",
    Opened: "8 July 1889",
    Lines: ["Metropolitan"],
  },
  Chigwell: {
    Station: "Chigwell",
    Zone: "4",
    Opened: "21-Nov-48",
    Lines: ["Central"],
  },
  "Chiswick Park": {
    Station: "Chiswick Park",
    Zone: "3",
    Opened: "1 July 1879",
    Lines: ["District"],
  },
  Chorleywood: {
    Station: "Chorleywood",
    Zone: "7",
    Opened: "8 July 1889",
    Lines: ["Metropolitan"],
  },
  "Clapham Common": {
    Station: "Clapham Common",
    Zone: "2",
    Opened: "3-Jun-00",
    Lines: ["Northern"],
  },
  "Clapham North": {
    Station: "Clapham North",
    Zone: "2",
    Opened: "3-Jun-00",
    Lines: ["Northern"],
  },
  "Clapham South": {
    Station: "Clapham South",
    Zone: "2 & 3",
    Opened: "13-Sep-26",
    Lines: ["Northern"],
  },
  Cockfosters: {
    Station: "Cockfosters",
    Zone: "5",
    Opened: "31-Jul-33",
    Lines: ["Piccadilly"],
  },
  Colindale: {
    Station: "Colindale",
    Zone: "4",
    Opened: "18-Aug-24",
    Lines: ["Northern"],
  },
  "Colliers Wood": {
    Station: "Colliers Wood",
    Zone: "3",
    Opened: "13-Sep-26",
    Lines: ["Northern"],
  },
  "Covent Garden": {
    Station: "Covent Garden",
    Zone: "1",
    Opened: "11-Apr-07",
    Lines: ["Piccadilly"],
  },
  Croxley: {
    Station: "Croxley",
    Zone: "7",
    Opened: "2-Nov-25",
    Lines: ["Metropolitan"],
  },
  "Dagenham East": {
    Station: "Dagenham East",
    Zone: "5",
    Opened: "2-Jun-02",
    Lines: ["District"],
  },
  "Dagenham Heathway": {
    Station: "Dagenham Heathway",
    Zone: "5",
    Opened: "12-Sep-32",
    Lines: ["District"],
  },
  Debden: {
    Station: "Debden",
    Zone: "6",
    Opened: "25-Sep-49",
    Lines: ["Central"],
  },
  "Dollis Hill": {
    Station: "Dollis Hill",
    Zone: "3",
    Opened: "1-Oct-09",
    Lines: ["Jubilee"],
  },
  "Ealing Broadway": {
    Station: "Ealing Broadway",
    Zone: "3",
    Opened: "1 July 1879",
    Lines: ["District", "Central"],
  },
  "Ealing Common": {
    Station: "Ealing Common",
    Zone: "3",
    Opened: "1 July 1879",
    Lines: ["District", "Piccadilly"],
  },
  "Earl's Court": {
    Station: "Earl's Court",
    Zone: "1 & 2",
    Opened: "30 October 1871",
    Lines: ["District", "Piccadilly"],
  },
  "East Acton": {
    Station: "East Acton",
    Zone: "2",
    Opened: "3-Aug-20",
    Lines: ["Central"],
  },
  "East Finchley": {
    Station: "East Finchley",
    Zone: "3",
    Opened: "3-Jul-39",
    Lines: ["Northern"],
  },
  "East Ham": {
    Station: "East Ham",
    Zone: "3 & 4",
    Opened: "2-Jun-02",
    Lines: ["District", "Hammersmith & City"],
  },
  "East Putney": {
    Station: "East Putney",
    Zone: "2 & 3",
    Opened: "3 June 1889",
    Lines: ["District"],
  },
  Eastcote: {
    Station: "Eastcote",
    Zone: "5",
    Opened: "26-May-06",
    Lines: ["Metropolitan", "Piccadilly"],
  },
  Edgware: {
    Station: "Edgware",
    Zone: "5",
    Opened: "18-Aug-24",
    Lines: ["Northern"],
  },
  "Edgware Road": {
    Station: "Edgware Road",
    Zone: "1",
    Opened: "1 October 1863",
    Lines: ["Hammersmith & City", "District", "Circle"],
  },
  "Elephant & Castle": {
    Station: "Elephant & Castle",
    Zone: "1 & 2",
    Opened: "18 December 1890",
    Lines: ["Northern", "Bakerloo"],
  },
  "Elm Park": {
    Station: "Elm Park",
    Zone: "6",
    Opened: "13-May-35",
    Lines: ["District"],
  },
  Embankment: {
    Station: "Embankment",
    Zone: "1",
    Opened: "30 May 1870",
    Lines: ["District", "Bakerloo", "Northern", "Circle"],
  },
  Epping: {
    Station: "Epping",
    Zone: "6",
    Opened: "25-Sep-49",
    Lines: ["Central"],
  },
  Euston: {
    Station: "Euston",
    Zone: "1",
    Opened: "22-Jun-07",
    Lines: ["Northern", "Victoria"],
  },
  "Euston Square": {
    Station: "Euston Square",
    Zone: "1",
    Opened: "10 January 1863",
    Lines: ["Metropolitan", "Circle", "Hammersmith & City"],
  },
  Fairlop: {
    Station: "Fairlop",
    Zone: "4",
    Opened: "31-May-48",
    Lines: ["Central"],
  },
  Farringdon: {
    Station: "Farringdon",
    Zone: "1",
    Opened: "10 January 1863",
    Lines: ["Metropolitan", "Circle", "Hammersmith & City"],
  },
  "Finchley Central": {
    Station: "Finchley Central",
    Zone: "4",
    Opened: "14-Apr-40",
    Lines: ["Northern"],
  },
  "Finchley Road": {
    Station: "Finchley Road",
    Zone: "2",
    Opened: "30 June 1879",
    Lines: ["Metropolitan", "Jubilee"],
  },
  "Finsbury Park": {
    Station: "Finsbury Park",
    Zone: "2",
    Opened: "15-Dec-06",
    Lines: ["Piccadilly", "Victoria"],
  },
  "Fulham Broadway": {
    Station: "Fulham Broadway",
    Zone: "2",
    Opened: "1 March 1880",
    Lines: ["District"],
  },
  "Gants Hill": {
    Station: "Gants Hill",
    Zone: "4",
    Opened: "14-Dec-47",
    Lines: ["Central"],
  },
  "Gloucester Road": {
    Station: "Gloucester Road",
    Zone: "1",
    Opened: "1 October 1868",
    Lines: ["District", "Piccadilly", "Circle"],
  },
  "Golders Green": {
    Station: "Golders Green",
    Zone: "3",
    Opened: "22-Jun-07",
    Lines: ["Northern"],
  },
  "Goldhawk Road": {
    Station: "Goldhawk Road",
    Zone: "2",
    Opened: "1-Apr-14",
    Lines: ["Hammersmith & City", "Circle"],
  },
  "Goodge Street": {
    Station: "Goodge Street",
    Zone: "1",
    Opened: "22-Jun-07",
    Lines: ["Northern"],
  },
  "Grange Hill": {
    Station: "Grange Hill",
    Zone: "4",
    Opened: "21-Nov-48",
    Lines: ["Central"],
  },
  "Great Portland Street": {
    Station: "Great Portland Street",
    Zone: "1",
    Opened: "10 January 1863",
    Lines: ["Metropolitan", "Circle", "Hammersmith & City"],
  },
  "Green Park": {
    Station: "Green Park",
    Zone: "1",
    Opened: "15-Dec-06",
    Lines: ["Piccadilly", "Victoria", "Jubilee"],
  },
  Greenford: {
    Station: "Greenford",
    Zone: "4",
    Opened: "30-Jun-47",
    Lines: ["Central"],
  },
  Gunnersbury: {
    Station: "Gunnersbury",
    Zone: "3",
    Opened: "1 June 1877",
    Lines: ["District"],
  },
  Hainault: {
    Station: "Hainault",
    Zone: "4",
    Opened: "31-May-48",
    Lines: ["Central"],
  },
  Hammersmith: {
    Station: "Hammersmith",
    Zone: "2",
    Opened: "13 June 1864",
    Lines: ["Hammersmith & City", "Circle"],
  },
  Hampstead: {
    Station: "Hampstead",
    Zone: "2 & 3",
    Opened: "22-Jun-07",
    Lines: ["Northern"],
  },
  "Hanger Lane": {
    Station: "Hanger Lane",
    Zone: "3",
    Opened: "30-Jun-47",
    Lines: ["Central"],
  },
  Harlesden: {
    Station: "Harlesden",
    Zone: "3",
    Opened: "16-Apr-17",
    Lines: ["Bakerloo"],
  },
  "Harrow & Wealdstone": {
    Station: "Harrow & Wealdstone",
    Zone: "5",
    Opened: "16-Apr-17",
    Lines: ["Bakerloo"],
  },
  "Harrow-on-the-Hill": {
    Station: "Harrow-on-the-Hill",
    Zone: "5",
    Opened: "2 August 1880",
    Lines: ["Metropolitan"],
  },
  "Hatton Cross": {
    Station: "Hatton Cross",
    Zone: "5 & 6",
    Opened: "19-Jul-75",
    Lines: ["Piccadilly"],
  },
  "Heathrow Terminal 4": {
    Station: "Heathrow Terminal 4",
    Zone: "6",
    Opened: "12-Apr-86",
    Lines: ["Piccadilly"],
  },
  "Heathrow Terminal 5": {
    Station: "Heathrow Terminal 5",
    Zone: "6",
    Opened: "27-Mar-08",
    Lines: ["Piccadilly"],
  },
  "Heathrow Terminals 1, 2, 3": {
    Station: "Heathrow Terminals 1, 2, 3",
    Zone: "6",
    Opened: "16-Dec-77",
    Lines: ["Piccadilly"],
  },
  "Hendon Central": {
    Station: "Hendon Central",
    Zone: "3 & 4",
    Opened: "19-Nov-23",
    Lines: ["Northern"],
  },
  "High Barnet": {
    Station: "High Barnet",
    Zone: "5",
    Opened: "14-Apr-40",
    Lines: ["Northern"],
  },
  "High Street Kensington": {
    Station: "High Street Kensington",
    Zone: "1",
    Opened: "1 October 1868",
    Lines: ["District", "Circle"],
  },
  "Highbury & Islington": {
    Station: "Highbury & Islington",
    Zone: "2",
    Opened: "1-Sep-68",
    Lines: ["Victoria"],
  },
  Highgate: {
    Station: "Highgate",
    Zone: "3",
    Opened: "19-Jan-41",
    Lines: ["Northern"],
  },
  Hillingdon: {
    Station: "Hillingdon",
    Zone: "6",
    Opened: "10-Dec-23",
    Lines: ["Metropolitan", "Piccadilly"],
  },
  Holborn: {
    Station: "Holborn",
    Zone: "1",
    Opened: "15-Dec-06",
    Lines: ["Central", "Piccadilly"],
  },
  "Holland Park": {
    Station: "Holland Park",
    Zone: "2",
    Opened: "30-Jul-00",
    Lines: ["Central"],
  },
  "Holloway Road": {
    Station: "Holloway Road",
    Zone: "2",
    Opened: "15-Dec-06",
    Lines: ["Piccadilly"],
  },
  Hornchurch: {
    Station: "Hornchurch",
    Zone: "6",
    Opened: "2-Jun-02",
    Lines: ["District"],
  },
  "Hounslow Central": {
    Station: "Hounslow Central",
    Zone: "4",
    Opened: "1 April 1886",
    Lines: ["Piccadilly"],
  },
  "Hounslow East": {
    Station: "Hounslow East",
    Zone: "4",
    Opened: "2-May-09",
    Lines: ["Piccadilly"],
  },
  "Hounslow West": {
    Station: "Hounslow West",
    Zone: "5",
    Opened: "21 July 1884",
    Lines: ["Piccadilly"],
  },
  "Hyde Park Corner": {
    Station: "Hyde Park Corner",
    Zone: "1",
    Opened: "15-Dec-06",
    Lines: ["Piccadilly"],
  },
  Ickenham: {
    Station: "Ickenham",
    Zone: "6",
    Opened: "25-Sep-05",
    Lines: ["Metropolitan", "Piccadilly"],
  },
  Kennington: {
    Station: "Kennington",
    Zone: "2",
    Opened: "18 December 1890",
    Lines: ["Northern"],
  },
  "Kensal Green": {
    Station: "Kensal Green",
    Zone: "2",
    Opened: "1-Oct-16",
    Lines: ["Bakerloo"],
  },
  "Kensington (Olympia)": {
    Station: "Kensington (Olympia)",
    Zone: "2",
    Opened: "1 July 1864",
    Lines: ["District"],
  },
  "Kentish Town": {
    Station: "Kentish Town",
    Zone: "2",
    Opened: "22-Jun-07",
    Lines: ["Northern"],
  },
  Kenton: {
    Station: "Kenton",
    Zone: "4",
    Opened: "16-Apr-17",
    Lines: ["Bakerloo"],
  },
  "Kew Gardens": {
    Station: "Kew Gardens",
    Zone: "3 & 4",
    Opened: "1 June 1877",
    Lines: ["District"],
  },
  Kilburn: {
    Station: "Kilburn",
    Zone: "2",
    Opened: "24 November 1879",
    Lines: ["Jubilee"],
  },
  "Kilburn Park": {
    Station: "Kilburn Park",
    Zone: "2",
    Opened: "31-Jan-15",
    Lines: ["Bakerloo"],
  },
  "King's Cross St. Pancras": {
    Station: "King's Cross St. Pancras",
    Zone: "1",
    Opened: "10 January 1863",
    Lines: [
      "Metropolitan",
      "Northern",
      "Piccadilly",
      "Circle",
      "Victoria",
      "Hammersmith & City",
    ],
  },
  Kingsbury: {
    Station: "Kingsbury",
    Zone: "4",
    Opened: "10-Dec-32",
    Lines: ["Jubilee"],
  },
  Knightsbridge: {
    Station: "Knightsbridge",
    Zone: "1",
    Opened: "15-Dec-06",
    Lines: ["Piccadilly"],
  },
  "Ladbroke Grove": {
    Station: "Ladbroke Grove",
    Zone: "2",
    Opened: "13 June 1864",
    Lines: ["Hammersmith & City", "Circle"],
  },
  "Lambeth North": {
    Station: "Lambeth North",
    Zone: "1",
    Opened: "10-Mar-06",
    Lines: ["Bakerloo"],
  },
  "Lancaster Gate": {
    Station: "Lancaster Gate",
    Zone: "1",
    Opened: "30-Jul-00",
    Lines: ["Central"],
  },
  "Latimer Road": {
    Station: "Latimer Road",
    Zone: "2",
    Opened: "16 December 1868",
    Lines: ["Hammersmith & City", "Circle"],
  },
  "Leicester Square": {
    Station: "Leicester Square",
    Zone: "1",
    Opened: "15-Dec-06",
    Lines: ["Piccadilly", "Northern"],
  },
  Leyton: {
    Station: "Leyton",
    Zone: "3",
    Opened: "5-May-47",
    Lines: ["Central"],
  },
  Leytonstone: {
    Station: "Leytonstone",
    Zone: "3 & 4",
    Opened: "5-May-47",
    Lines: ["Central"],
  },
  "Liverpool Street": {
    Station: "Liverpool Street",
    Zone: "1",
    Opened: "1 February 1875",
    Lines: ["Metropolitan", "Central", "Circle", "Hammersmith & City"],
  },
  "London Bridge": {
    Station: "London Bridge",
    Zone: "1",
    Opened: "24-Feb-00",
    Lines: ["Northern", "Jubilee"],
  },
  Loughton: {
    Station: "Loughton",
    Zone: "6",
    Opened: "21-Nov-48",
    Lines: ["Central"],
  },
  "Maida Vale": {
    Station: "Maida Vale",
    Zone: "2",
    Opened: "6-Jun-15",
    Lines: ["Bakerloo"],
  },
  "Manor House": {
    Station: "Manor House",
    Zone: "2 & 3",
    Opened: "19-Sep-32",
    Lines: ["Piccadilly"],
  },
  "Mansion House": {
    Station: "Mansion House",
    Zone: "1",
    Opened: "3 July 1871",
    Lines: ["District", "Circle"],
  },
  "Marble Arch": {
    Station: "Marble Arch",
    Zone: "1",
    Opened: "30-Jul-00",
    Lines: ["Central"],
  },
  Marylebone: {
    Station: "Marylebone",
    Zone: "1",
    Opened: "27-Mar-07",
    Lines: ["Bakerloo"],
  },
  "Mile End": {
    Station: "Mile End",
    Zone: "2",
    Opened: "2-Jun-02",
    Lines: ["District", "Hammersmith & City", "Central"],
  },
  "Mill Hill East": {
    Station: "Mill Hill East",
    Zone: "4",
    Opened: "18-May-41",
    Lines: ["Northern"],
  },
  Monument: {
    Station: "Monument",
    Zone: "1",
    Opened: "6 October 1884",
    Lines: ["District", "Circle"],
  },
  "Moor Park": {
    Station: "Moor Park",
    Zone: "6 & 7",
    Opened: "9-May-10",
    Lines: ["Metropolitan"],
  },
  Moorgate: {
    Station: "Moorgate",
    Zone: "1",
    Opened: "23 December 1865",
    Lines: ["Metropolitan", "Northern", "Circle", "Hammersmith & City"],
  },
  Morden: {
    Station: "Morden",
    Zone: "4",
    Opened: "13-Sep-26",
    Lines: ["Northern"],
  },
  "Mornington Crescent": {
    Station: "Mornington Crescent",
    Zone: "2",
    Opened: "22-Jun-07",
    Lines: ["Northern"],
  },
  Neasden: {
    Station: "Neasden",
    Zone: "3",
    Opened: "2 August 1880",
    Lines: ["Jubilee"],
  },
  "Newbury Park": {
    Station: "Newbury Park",
    Zone: "4",
    Opened: "14-Dec-47",
    Lines: ["Central"],
  },
  "North Acton": {
    Station: "North Acton",
    Zone: "2 & 3",
    Opened: "5-Nov-23",
    Lines: ["Central"],
  },
  "North Ealing": {
    Station: "North Ealing",
    Zone: "3",
    Opened: "23-Jun-03",
    Lines: ["Piccadilly"],
  },
  "North Greenwich": {
    Station: "North Greenwich",
    Zone: "2 & 3",
    Opened: "14-May-99",
    Lines: ["Jubilee"],
  },
  "North Harrow": {
    Station: "North Harrow",
    Zone: "5",
    Opened: "22-Mar-15",
    Lines: ["Metropolitan"],
  },
  "North Wembley": {
    Station: "North Wembley",
    Zone: "4",
    Opened: "16-Apr-17",
    Lines: ["Bakerloo"],
  },
  Northfields: {
    Station: "Northfields",
    Zone: "3",
    Opened: "16-Apr-08",
    Lines: ["Piccadilly"],
  },
  Northolt: {
    Station: "Northolt",
    Zone: "5",
    Opened: "21-Nov-48",
    Lines: ["Central"],
  },
  "Northwick Park": {
    Station: "Northwick Park",
    Zone: "4",
    Opened: "28-Jun-23",
    Lines: ["Metropolitan"],
  },
  Northwood: {
    Station: "Northwood",
    Zone: "6",
    Opened: "1 September 1887",
    Lines: ["Metropolitan"],
  },
  "Northwood Hills": {
    Station: "Northwood Hills",
    Zone: "6",
    Opened: "13-Nov-33",
    Lines: ["Metropolitan"],
  },
  "Notting Hill Gate": {
    Station: "Notting Hill Gate",
    Zone: "1 & 2",
    Opened: "1 October 1868",
    Lines: ["District", "Central", "Circle"],
  },
  Oakwood: {
    Station: "Oakwood",
    Zone: "5",
    Opened: "13-Mar-33",
    Lines: ["Piccadilly"],
  },
  "Old Street": {
    Station: "Old Street",
    Zone: "1",
    Opened: "17-Nov-01",
    Lines: ["Northern"],
  },
  Osterley: {
    Station: "Osterley",
    Zone: "4",
    Opened: "1 May 1883",
    Lines: ["Piccadilly"],
  },
  Oval: {
    Station: "Oval",
    Zone: "2",
    Opened: "18 December 1890",
    Lines: ["Northern"],
  },
  "Oxford Circus": {
    Station: "Oxford Circus",
    Zone: "1",
    Opened: "30-Jul-00",
    Lines: ["Central", "Bakerloo", "Victoria"],
  },
  Paddington: {
    Station: "Paddington",
    Zone: "1",
    Opened: "10 January 1863",
    Lines: ["Hammersmith & City", "District", "Circle", "Bakerloo"],
  },
  "Park Royal": {
    Station: "Park Royal",
    Zone: "3",
    Opened: "23-Jun-03",
    Lines: ["Piccadilly"],
  },
  "Parsons Green": {
    Station: "Parsons Green",
    Zone: "2",
    Opened: "1 March 1880",
    Lines: ["District"],
  },
  Perivale: {
    Station: "Perivale",
    Zone: "4",
    Opened: "30-Jun-47",
    Lines: ["Central"],
  },
  "Piccadilly Circus": {
    Station: "Piccadilly Circus",
    Zone: "1",
    Opened: "10-Mar-06",
    Lines: ["Bakerloo", "Piccadilly"],
  },
  Pimlico: {
    Station: "Pimlico",
    Zone: "1",
    Opened: "14-Sep-72",
    Lines: ["Victoria"],
  },
  Pinner: {
    Station: "Pinner",
    Zone: "5",
    Opened: "25 May 1885",
    Lines: ["Metropolitan"],
  },
  Plaistow: {
    Station: "Plaistow",
    Zone: "3",
    Opened: "2-Jun-02",
    Lines: ["District", "Hammersmith & City"],
  },
  "Preston Road": {
    Station: "Preston Road",
    Zone: "4",
    Opened: "21-May-08",
    Lines: ["Metropolitan"],
  },
  "Putney Bridge": {
    Station: "Putney Bridge",
    Zone: "2",
    Opened: "1 March 1880",
    Lines: ["District"],
  },
  "Queen's Park": {
    Station: "Queen's Park",
    Zone: "2",
    Opened: "11-Feb-15",
    Lines: ["Bakerloo"],
  },
  Queensbury: {
    Station: "Queensbury",
    Zone: "4",
    Opened: "16-Dec-34",
    Lines: ["Jubilee"],
  },
  Queensway: {
    Station: "Queensway",
    Zone: "1",
    Opened: "30-Jul-00",
    Lines: ["Central"],
  },
  "Ravenscourt Park": {
    Station: "Ravenscourt Park",
    Zone: "2",
    Opened: "1 June 1877",
    Lines: ["District"],
  },
  "Rayners Lane": {
    Station: "Rayners Lane",
    Zone: "5",
    Opened: "26-May-06",
    Lines: ["Metropolitan", "Piccadilly"],
  },
  Redbridge: {
    Station: "Redbridge",
    Zone: "4",
    Opened: "14-Dec-47",
    Lines: ["Central"],
  },
  "Regent's Park": {
    Station: "Regent's Park",
    Zone: "1",
    Opened: "10-Mar-06",
    Lines: ["Bakerloo"],
  },
  Richmond: {
    Station: "Richmond",
    Zone: "4",
    Opened: "1 June 1877",
    Lines: ["District"],
  },
  Rickmansworth: {
    Station: "Rickmansworth",
    Zone: "7",
    Opened: "1 September 1887",
    Lines: ["Metropolitan"],
  },
  "Roding Valley": {
    Station: "Roding Valley",
    Zone: "4",
    Opened: "21-Nov-48",
    Lines: ["Central"],
  },
  "Royal Oak": {
    Station: "Royal Oak",
    Zone: "2",
    Opened: "30 October 1871",
    Lines: ["Hammersmith & City", "Circle"],
  },
  Ruislip: {
    Station: "Ruislip",
    Zone: "6",
    Opened: "4-Jul-04",
    Lines: ["Metropolitan", "Piccadilly"],
  },
  "Ruislip Gardens": {
    Station: "Ruislip Gardens",
    Zone: "5",
    Opened: "29-Nov-48",
    Lines: ["Central"],
  },
  "Ruislip Manor": {
    Station: "Ruislip Manor",
    Zone: "6",
    Opened: "5-Aug-12",
    Lines: ["Metropolitan", "Piccadilly"],
  },
  "Russell Square": {
    Station: "Russell Square",
    Zone: "1",
    Opened: "15-Dec-06",
    Lines: ["Piccadilly"],
  },
  "Seven Sisters": {
    Station: "Seven Sisters",
    Zone: "3",
    Opened: "1-Sep-68",
    Lines: ["Victoria"],
  },
  "Shepherd's Bush": {
    Station: "Shepherd's Bush",
    Zone: "2",
    Opened: "30-Jul-00",
    Lines: ["Central"],
  },
  "Shepherd's Bush Market": {
    Station: "Shepherd's Bush Market",
    Zone: "2",
    Opened: "13 June 1864",
    Lines: ["Hammersmith & City", "Circle"],
  },
  "Sloane Square": {
    Station: "Sloane Square",
    Zone: "1",
    Opened: "24 December 1868",
    Lines: ["District", "Circle"],
  },
  Snaresbrook: {
    Station: "Snaresbrook",
    Zone: "4",
    Opened: "14-Dec-47",
    Lines: ["Central"],
  },
  "South Ealing": {
    Station: "South Ealing",
    Zone: "3",
    Opened: "1 May 1883",
    Lines: ["Piccadilly"],
  },
  "South Harrow": {
    Station: "South Harrow",
    Zone: "5",
    Opened: "28-Jun-03",
    Lines: ["Piccadilly"],
  },
  "South Kensington": {
    Station: "South Kensington",
    Zone: "1",
    Opened: "24 December 1868",
    Lines: ["District", "Piccadilly", "Circle"],
  },
  "South Kenton": {
    Station: "South Kenton",
    Zone: "4",
    Opened: "3-Jul-33",
    Lines: ["Bakerloo"],
  },
  "South Ruislip": {
    Station: "South Ruislip",
    Zone: "5",
    Opened: "23-Nov-48",
    Lines: ["Central"],
  },
  "South Wimbledon": {
    Station: "South Wimbledon",
    Zone: "3 & 4",
    Opened: "13-Sep-26",
    Lines: ["Northern"],
  },
  "South Woodford": {
    Station: "South Woodford",
    Zone: "4",
    Opened: "14-Dec-47",
    Lines: ["Central"],
  },
  Southfields: {
    Station: "Southfields",
    Zone: "3",
    Opened: "3 June 1889",
    Lines: ["District"],
  },
  Southgate: {
    Station: "Southgate",
    Zone: "4",
    Opened: "13-Mar-33",
    Lines: ["Piccadilly"],
  },
  Southwark: {
    Station: "Southwark",
    Zone: "1",
    Opened: "24-Sep-99",
    Lines: ["Jubilee"],
  },
  "St. James's Park": {
    Station: "St. James's Park",
    Zone: "1",
    Opened: "24 December 1868",
    Lines: ["District", "Circle"],
  },
  "St. John's Wood": {
    Station: "St. John's Wood",
    Zone: "2",
    Opened: "20-Nov-39",
    Lines: ["Jubilee"],
  },
  "St. Paul's": {
    Station: "St. Paul's",
    Zone: "1",
    Opened: "30-Jul-00",
    Lines: ["Central"],
  },
  "Stamford Brook": {
    Station: "Stamford Brook",
    Zone: "2",
    Opened: "1-Feb-12",
    Lines: ["District"],
  },
  Stanmore: {
    Station: "Stanmore",
    Zone: "5",
    Opened: "10-Dec-32",
    Lines: ["Jubilee"],
  },
  "Stepney Green": {
    Station: "Stepney Green",
    Zone: "2",
    Opened: "23-Jun-02",
    Lines: ["District", "Hammersmith & City"],
  },
  Stockwell: {
    Station: "Stockwell",
    Zone: "2",
    Opened: "18 December 1890",
    Lines: ["Northern", "Victoria"],
  },
  "Stonebridge Park": {
    Station: "Stonebridge Park",
    Zone: "3",
    Opened: "16-Apr-17",
    Lines: ["Bakerloo"],
  },
  Stratford: {
    Station: "Stratford",
    Zone: "3",
    Opened: "4-Dec-46",
    Lines: ["Central", "Jubilee"],
  },
  "Sudbury Hill": {
    Station: "Sudbury Hill",
    Zone: "4",
    Opened: "28-Jun-03",
    Lines: ["Piccadilly"],
  },
  "Sudbury Town": {
    Station: "Sudbury Town",
    Zone: "4",
    Opened: "28-Jun-03",
    Lines: ["Piccadilly"],
  },
  "Swiss Cottage": {
    Station: "Swiss Cottage",
    Zone: "2",
    Opened: "20-Nov-39",
    Lines: ["Jubilee"],
  },
  Temple: {
    Station: "Temple",
    Zone: "1",
    Opened: "30 May 1870",
    Lines: ["District", "Circle"],
  },
  "Theydon Bois": {
    Station: "Theydon Bois",
    Zone: "6",
    Opened: "25-Sep-49",
    Lines: ["Central"],
  },
  "Tooting Bec": {
    Station: "Tooting Bec",
    Zone: "3",
    Opened: "13-Sep-26",
    Lines: ["Northern"],
  },
  "Tooting Broadway": {
    Station: "Tooting Broadway",
    Zone: "3",
    Opened: "13-Sep-26",
    Lines: ["Northern"],
  },
  "Tottenham Court Road": {
    Station: "Tottenham Court Road",
    Zone: "1",
    Opened: "30-Jul-00",
    Lines: ["Central", "Northern"],
  },
  "Tottenham Hale": {
    Station: "Tottenham Hale",
    Zone: "3",
    Opened: "1-Sep-68",
    Lines: ["Victoria"],
  },
  "Totteridge & Whetstone": {
    Station: "Totteridge & Whetstone",
    Zone: "4",
    Opened: "14-Apr-40",
    Lines: ["Northern"],
  },
  "Tower Hill": {
    Station: "Tower Hill",
    Zone: "1",
    Opened: "25 September 1882",
    Lines: ["District", "Circle"],
  },
  "Tufnell Park": {
    Station: "Tufnell Park",
    Zone: "2",
    Opened: "22-Jun-07",
    Lines: ["Northern"],
  },
  "Turnham Green": {
    Station: "Turnham Green",
    Zone: "2 & 3",
    Opened: "1 June 1877",
    Lines: ["District", "Piccadilly"],
  },
  "Turnpike Lane": {
    Station: "Turnpike Lane",
    Zone: "3",
    Opened: "19-Sep-32",
    Lines: ["Piccadilly"],
  },
  Upminster: {
    Station: "Upminster",
    Zone: "6",
    Opened: "2-Jun-02",
    Lines: ["District"],
  },
  "Upminster Bridge": {
    Station: "Upminster Bridge",
    Zone: "6",
    Opened: "17-Dec-34",
    Lines: ["District"],
  },
  Upney: {
    Station: "Upney",
    Zone: "4",
    Opened: "12-Sep-32",
    Lines: ["District"],
  },
  "Upton Park": {
    Station: "Upton Park",
    Zone: "3",
    Opened: "2-Jun-02",
    Lines: ["District", "Hammersmith & City"],
  },
  Uxbridge: {
    Station: "Uxbridge",
    Zone: "6",
    Opened: "4-Jul-04",
    Lines: ["Metropolitan", "Piccadilly"],
  },
  Vauxhall: {
    Station: "Vauxhall",
    Zone: "1 & 2",
    Opened: "23-Jul-71",
    Lines: ["Victoria"],
  },
  Victoria: {
    Station: "Victoria",
    Zone: "1",
    Opened: "24 December 1868",
    Lines: ["District", "Circle", "Victoria"],
  },
  "Walthamstow Central": {
    Station: "Walthamstow Central",
    Zone: "3",
    Opened: "1-Sep-68",
    Lines: ["Victoria"],
  },
  Wanstead: {
    Station: "Wanstead",
    Zone: "4",
    Opened: "14-Dec-47",
    Lines: ["Central"],
  },
  "Warren Street": {
    Station: "Warren Street",
    Zone: "1",
    Opened: "22-Jun-07",
    Lines: ["Northern", "Victoria"],
  },
  "Warwick Avenue": {
    Station: "Warwick Avenue",
    Zone: "2",
    Opened: "31-Jan-15",
    Lines: ["Bakerloo"],
  },
  Waterloo: {
    Station: "Waterloo",
    Zone: "1",
    Opened: "10-Mar-06",
    Lines: ["Waterloo & City", "Bakerloo", "Northern", "Jubilee"],
  },
  Watford: {
    Station: "Watford",
    Zone: "7",
    Opened: "2-Nov-25",
    Lines: ["Metropolitan"],
  },
  "Wembley Central": {
    Station: "Wembley Central",
    Zone: "4",
    Opened: "16-Apr-17",
    Lines: ["Bakerloo"],
  },
  "Wembley Park": {
    Station: "Wembley Park",
    Zone: "4",
    Opened: "12 May 1894",
    Lines: ["Metropolitan", "Jubilee"],
  },
  "West Acton": {
    Station: "West Acton",
    Zone: "3",
    Opened: "5-Nov-23",
    Lines: ["Central"],
  },
  "West Brompton": {
    Station: "West Brompton",
    Zone: "2",
    Opened: "12 April 1869",
    Lines: ["District"],
  },
  "West Finchley": {
    Station: "West Finchley",
    Zone: "4",
    Opened: "14-Apr-40",
    Lines: ["Northern"],
  },
  "West Ham": {
    Station: "West Ham",
    Zone: "3",
    Opened: "2-Jun-02",
    Lines: ["District", "Hammersmith & City", "Jubilee"],
  },
  "West Hampstead": {
    Station: "West Hampstead",
    Zone: "2",
    Opened: "30 June 1879",
    Lines: ["Jubilee"],
  },
  "West Harrow": {
    Station: "West Harrow",
    Zone: "5",
    Opened: "17-Nov-13",
    Lines: ["Metropolitan"],
  },
  "West Kensington": {
    Station: "West Kensington",
    Zone: "2",
    Opened: "9 September 1874",
    Lines: ["District"],
  },
  "West Ruislip": {
    Station: "West Ruislip",
    Zone: "6",
    Opened: "21-Nov-48",
    Lines: ["Central"],
  },
  "Westbourne Park": {
    Station: "Westbourne Park",
    Zone: "2",
    Opened: "1 February 1866",
    Lines: ["Hammersmith & City", "Circle"],
  },
  Westminster: {
    Station: "Westminster",
    Zone: "1",
    Opened: "24 December 1868",
    Lines: ["District", "Circle", "Jubilee"],
  },
  "White City": {
    Station: "White City",
    Zone: "2",
    Opened: "23-Nov-47",
    Lines: ["Central"],
  },
  Whitechapel: {
    Station: "Whitechapel",
    Zone: "2",
    Opened: "1 October 1884",
    Lines: ["District", "Hammersmith & City"],
  },
  "Willesden Green": {
    Station: "Willesden Green",
    Zone: "2 & 3",
    Opened: "24 November 1879",
    Lines: ["Jubilee"],
  },
  "Willesden Junction": {
    Station: "Willesden Junction",
    Zone: "2 & 3",
    Opened: "10-May-15",
    Lines: ["Bakerloo"],
  },
  Wimbledon: {
    Station: "Wimbledon",
    Zone: "3",
    Opened: "3 June 1889",
    Lines: ["District"],
  },
  "Wimbledon Park": {
    Station: "Wimbledon Park",
    Zone: "3",
    Opened: "3 June 1889",
    Lines: ["District"],
  },
  "Wood Green": {
    Station: "Wood Green",
    Zone: "3",
    Opened: "19-Sep-32",
    Lines: ["Piccadilly"],
  },
  "Wood Lane": {
    Station: "Wood Lane",
    Zone: "2",
    Opened: "12-Oct-08",
    Lines: ["Hammersmith & City", "Circle"],
  },
  Woodford: {
    Station: "Woodford",
    Zone: "4",
    Opened: "14-Dec-47",
    Lines: ["Central"],
  },
  "Woodside Park": {
    Station: "Woodside Park",
    Zone: "4",
    Opened: "12-Apr-40",
    Lines: ["Northern"],
  },
};
