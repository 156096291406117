import { stationNames, stationsObj, allLines } from "./data";

//<T extends unknown>(array: T[]): T
const randomInArray = (array) => {
  return array[Math.round(Math.random() * (array.length - 1))];
};

//<T extends Array<unknown>>(array: T): T
const shuffleArray = (array) => array.sort((_) => Math.random() - 0.5);

const getAnswers = (stationLines) => {
  const realRandomLine = shuffleArray(stationLines)[0];
  const notRealLines = shuffleArray(
    allLines.filter((line) => !stationLines.includes(line))
  ).slice(0, 3);

  return shuffleArray([realRandomLine, ...notRealLines]);
};

export const buildQuestion = () => {
  const stationName = randomInArray(stationNames);

  const station = stationsObj[stationName];

  const stationLines = station.Lines;

  const answers = getAnswers(stationLines);

  return {
    id: stationName,
    name: stationName,
    answers,
  };
};

export const checkAnswer = (stationName, answer) => {
  const station = stationsObj[stationName];

  return station.Lines.includes(answer);
};
