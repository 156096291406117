import { useState, useEffect } from "preact/hooks";
import styled from "styled-components";
import styles from "./style.module.css";
import { colourMap } from "./station-color";

import { eventTrigger, eventEndGame } from "../../events";
import { startGame, getQuesiton, submitAnswer } from "../../requests/game";

const waitFor = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const GameOver = ({ handleRetry, handleExitGame, score }) => {
  return (
    <div>
      <h2>Game Over!</h2>

      <p>Score: {score}</p>

      <div className={styles.buttonSpacer}>
        <button className={styles.blueButton} onClick={handleRetry}>
          Retry
        </button>
        <button className={styles.blueButton} onClick={handleExitGame}>
          Exit
        </button>
      </div>
    </div>
  );
};

const AnswerButton = styled.button`
  background-color: ${(props) => {
    if (props.success) return "#84B817";
    if (props.error) return "#E21836";

    return "#fff";
  }};
  height: 41px;
  border: 1px solid #3c3c3c;
  ${(props) => {
    if (props.success || props.error) return "";

    return `border-top: 5px solid ${colourMap[props.line]};`;
  }}
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-family: "Helvetica Neue", arial, sans-serif;
  cursor: pointer;
  color: ${(props) => (props.error || props.success ? "white" : "black")};
`;

const Answers = ({
  question,
  handleQuestion,
  handleEndGame,
  handleScoreUpdate,
}) => {
  const [active, setActive] = useState(true);
  const [successIndex, setSuccessIndex] = useState(-1);
  const [errorIndex, setErrorIndex] = useState(-1);

  const answerQuestion = async (answer, index) => {
    if (!active) return;

    setActive(false);

    const answerReturn = await submitAnswer(question, answer);

    if (answerReturn.status === true) {
      // correct.
      setSuccessIndex(index);
      handleScoreUpdate();

      // run next question

      await waitFor(1000);

      handleQuestion();
      setSuccessIndex(-1);
      setErrorIndex(-1);
    } else {
      // incorrect.
      const successLookup = question.answers.indexOf(answerReturn.correct);
      setSuccessIndex(successLookup);
      setErrorIndex(index);

      await waitFor(1500);
      handleEndGame();
    }

    setActive(true);

    // wait time.
    // reset indexes of error and success.
  };

  return (
    <div className={styles.questions}>
      {question.answers.map((answer, index) => {
        const success = index === successIndex;
        const error = index === errorIndex;
        return (
          <AnswerButton
            type="button"
            success={success}
            error={error}
            line={answer}
            onClick={() => answerQuestion(answer, index)}
          >
            {answer}
          </AnswerButton>
        );
      })}
    </div>
  );
};

export const Game = ({ handleExitGame }) => {
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState(null);
  const [score, setScore] = useState(0);

  useEffect(() => {
    handleStartGame();
  }, []);

  const handleStartGame = async () => {
    handleQuestion();
  };

  const handleQuestion = async () => {
    const newQuestion = await getQuesiton();
    setQuestion(newQuestion);

    setLoading(false);
  };

  const handleEndGame = () => {
    eventEndGame(score);
    setActive(false);
  };

  const handleRetry = async () => {
    eventTrigger("retry");
    setScore(0);
    setLoading(true);
    setActive(true);
    await handleStartGame();

    setLoading(false);
  };

  const handleScoreUpdate = async () => {
    setScore((s) => s + 1);
  };

  if (loading) return <div>loading...</div>;

  if (!active)
    return (
      <GameOver
        handleRetry={handleRetry}
        handleExitGame={handleExitGame}
        score={score}
      />
    );

  return (
    <>
      <h2 className={styles.title}>
        SELECT THE
        <br />
        CORRECT LINE!
      </h2>

      <p className={styles.stationName}>{question.name}</p>

      <Answers
        question={question}
        handleQuestion={handleQuestion}
        handleEndGame={handleEndGame}
        handleScoreUpdate={handleScoreUpdate}
      />

      <p className={styles.score}>SCORE: {score}</p>
    </>
  );
};
