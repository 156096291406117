export const eventTrigger = (action) =>
  window.gtag &&
  window.gtag("event", action, {
    event_category: "Game",
  });

export const eventEndGame = (score) =>
  window.gtag &&
  window.gtag("event", "end-game", {
    event_category: "Game",
    event_label: "score",
    value: score,
  });
